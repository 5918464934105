import React, { useState } from "react";
import Sound from "react-sound";
import Gifts from "./images/background.png";
import Arrow from "./images/arrow.png";
import Arrow1 from "./images/arrow1.png";
import Arrow2 from "./images/arrow2.png";
import Arrow3 from "./images/arrow3.png";
import Arrow4 from "./images/arrow4.png";
import Spa from "./images/spa.png";
import Lotte from "./images/Lotte.png";
import Titel from "./images/Titel.png";
import Hotel from "./images/Hotel.png";
import Hotel2 from "./images/hotel2.png";
import Weihnachtsmarkt from "./images/weihnachtsmarkt.png";
import Weihnachtsmarkt2 from "./images/weihnachtsmarkt2.png";
import Song from "./audio/wennliebekommt.mp3";

export default function App() {
  const [password, setPassword] = useState(false);
  // const [play, setPlay] = useState(false);
  const [animation, setAnimation] = useState(false);
  const [textval, setTextval] = useState("");

  const [one, setOne] = useState(false);
  const [two, setTwo] = useState(false);
  const [three, setThree] = useState(false);
  const [four, setFour] = useState(false);

  const PlaySound = (
    handleSongLoading,
    handleSongPlaying,
    handleSongFinishedPlaying
  ) => {
    const [isPlaying, setIsPlaying] = useState(false);

    return (
      <div>
        <button onClick={() => setIsPlaying(!isPlaying)}>
          {!isPlaying ? "Play" : "Stop"}
        </button>
        <Sound
          url={Song}
          playStatus={isPlaying ? Sound.status.PLAYING : Sound.status.STOPPED}
          playFromPosition={300}
          onLoading={handleSongLoading}
          onPlaying={handleSongPlaying}
          onFinishedPlaying={handleSongFinishedPlaying}
        />
      </div>
    );
  };

  function Submit() {
    const value = "Lotte";
    if (textval.toLocaleLowerCase() === value.toLocaleLowerCase()) {
      setAnimation(true);
      setTimeout(() => {
        setPassword(true);
        // setPlay(true);
      }, [1500]);
    }
  }

  // React.useEffect(() => {
  //   if (play === true) {
  //     var audio = new Audio(Song);
  //     console.log(play);
  //     audio.play();
  //   }
  // });

  return (
    <>
      {password ? (
        <div className="container">
          <div className="main">
            <div className="header">
              <h1>Happy Birthday</h1>
              <h2>
                mein <br /> Schatz
              </h2>
            </div>
            <a href="#text">
              <p id="scroll" className="scroll">
                scroll
              </p>
            </a>
            <div id="text" className="text">
              <PlaySound />
              <p>
                Liebe Christin,
                <br />
                <br />
                5.529.600 Sekunden sind seit unserem ersten Treffen vergangen.
                In diesen Sekunden hat sich ein Prozess in Gang gesetzt der
                unaufhaltsam meine Zuneigung zu Dir forcierte. Du hast mir
                gezeigt wie wohl und geborgen man sich in der Nähe einer Person
                fühlen kann und, dass es diese Schmetterlinge im Bauch wirklich
                gibt. Trotz der wenigen Wochen fühlt sich unsere gemeinsame Zeit
                schon an wie eine Ewigkeit. Es gibt so viel verbindende Momente
                die nur wir miteinander teilen und unsere eigenen nennen können.
                Du bist mir in den vergangenen Tagen, Wochen und jetzt sogar
                schon Monaten so ans Herz gewachsen, dass ich mir sicher bin
                unsere beiden getrennten Pfade werden auf einem gemeinsamen Weg
                zusammenfinden. Das sagen mir die Instanzen Herz, Bauch und
                Kopf. Wenn auf diesem Weg die Liebe kommt dann will ich, dass
                sie bleibt. In diesem Sinne alles Liebe zum Geburtstag und auf
                ein gemeinsames Wir. Da ich heute (leider) nicht bei Dir sein
                kann musst du Dich nun allein auf den Weg zu deinem virtuellen
                Geburtstagstisch begeben und deine Geschenke auspacken. Aber
                vielleicht findest du dort ja ein kleines Puzzleteil zu unserem
                gemeinsamen Traum.
                <br />
                <br />
                Dein Paul
              </p>
            </div>
            <div className="gifts">
              <img src={Gifts} alt="gift" />
              <button
                onClick={() => {
                  setOne(!one);
                  setTwo(false);
                  setThree(false);
                  setFour(false);
                  // setPlay(false);
                }}
              >
                1
              </button>
              <button
                onClick={() => {
                  setOne(false);
                  setTwo(!two);
                  setThree(false);
                  setFour(false);
                  // setPlay(false);
                }}
              >
                2
              </button>
              <button
                onClick={() => {
                  setOne(false);
                  setTwo(false);
                  setThree(!three);
                  setFour(false);
                  // setPlay(false);
                }}
              >
                3
              </button>
              <button
                onClick={() => {
                  setOne(false);
                  setTwo(false);
                  setThree(false);
                  setFour(!four);
                  // setPlay(false);
                }}
              >
                4
              </button>
              <div
                className="giftone"
                onClick={() => {
                  setOne(!one);
                  setTwo(false);
                  setThree(false);
                  setFour(false);
                  // setPlay(false);
                }}
              ></div>
              <div
                className="gifttwo"
                onClick={() => {
                  setOne(false);
                  setTwo(!two);
                  setThree(false);
                  setFour(false);
                  // setPlay(false);
                }}
              ></div>
              <div
                className="giftthree"
                onClick={() => {
                  setOne(false);
                  setTwo(false);
                  setThree(!three);
                  setFour(false);
                  // setPlay(false);
                }}
              ></div>
              <div
                className="giftfour"
                onClick={() => {
                  setOne(false);
                  setTwo(false);
                  setThree(false);
                  setFour(!four);
                  // setPlay(false);
                }}
              ></div>

              <div className="center">
                {one ? (
                  <div className="centerblock">
                    <p className="centertitle">Unser erstes Konzert</p>
                    <div className="centerimages">
                      <img className="firstimage" src={Lotte} alt="first" />
                      <img className="secondimage" src={Titel} alt="second" />
                    </div>
                  </div>
                ) : two ? (
                  <div className="centerblock">
                    <p className="centertitle blocktwo">
                      Eine Übernachtung an dem Ort, wo alles begann.
                    </p>
                    <div className="centerimages">
                      <img
                        className="firstimage secondblock"
                        src={Hotel2}
                        alt="first"
                      />
                      <img
                        className="secondimage secondblock"
                        src={Hotel}
                        alt="second"
                      />
                    </div>
                  </div>
                ) : three ? (
                  <div className="centerblock">
                    <p className="centertitle">
                      Eine Massage, damit du auch einmal in den Genuss kommst.
                    </p>
                    <div className="centerimages">
                      <img
                        className="firstimagetwo thirdblock"
                        src={Spa}
                        alt="first"
                      />
                    </div>
                  </div>
                ) : four ? (
                  <div className="centerblock">
                    <p className="centertitle blocktwo">
                      Ein romantischer Ausklang auf dem Weihnachtsmarkt.
                    </p>
                    <div className="centerimages">
                      <img
                        className="firstimage fourth"
                        src={Weihnachtsmarkt}
                        alt="first"
                      />
                      <img
                        className="secondimage"
                        src={Weihnachtsmarkt2}
                        alt="second"
                      />
                    </div>
                  </div>
                ) : (
                  <div className="cal2action">
                    <p>
                      Klicke <br /> um deine Geschenke <br /> auszupacken.
                    </p>
                    <img src={Arrow1} alt="arrow1" />
                    <img src={Arrow2} alt="arrow2" />
                    <img src={Arrow3} alt="arrow3" />
                    <img src={Arrow4} alt="arrow4" />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className={animation ? "lockscreenanimation" : "lockscreen"}>
          <input
            onChange={(e) => setTextval(e.target.value)}
            placeholder="Type the secret key here.."
          />
          <div onClick={Submit} className="submit">
            enter <img src={Arrow} alt="arrow" />
          </div>
        </div>
      )}
    </>
  );
}
